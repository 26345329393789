import styled from "styled-components";

import Color from "../../constants/Color";
import { SpacingType } from "../../constants/Spacing";

export const ButtonType = {
    PRIMARY: 1,
    SECONDARY: 2,
    SECONDARY_WHITE: 3,
} as const;

type ButtonTypeType = typeof ButtonType[keyof typeof ButtonType];

export const ButtonSize = {
    REGULAR: 1,
    LARGE: 2,
} as const;

type ButtonSizeType = typeof ButtonSize[keyof typeof ButtonSize];

interface Props {
    buttonType: ButtonTypeType;
    buttonSize: ButtonSizeType;
    margin?: SpacingType;
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
    marginLeft?: SpacingType;
    marginRight?: SpacingType;
}

const Button = styled.button<Props>`
    border: 2px solid black;

    ${({ buttonSize }: Props) => {
        if (buttonSize === ButtonSize.LARGE) {
            return `
                min-width: 240px;
                height: 72px;
                border-radius: 10px;
                font-size: 24px;
            `;
        }

        return `
            min-width: 150px;
            height: 36px;
            border-radius: 18px;
            font-size: 18px;
        `;
    }}

    ${({ buttonType }: Props) => {
        if (buttonType === ButtonType.PRIMARY) {
            return `
                background-color: ${Color.BRAND_1};
                color: ${Color.TEXT_LIGHT_1};
                border-color: ${Color.BRAND_1};
            `;
        }

        if (buttonType === ButtonType.SECONDARY) {
            return `
                background-color: transparent;
                color: ${Color.BRAND_1};
                border-color: ${Color.BRAND_1};
            `;
        }

        return `
            background-color: transparent;
            color: ${Color.TEXT_LIGHT_1};
            border-color: ${Color.TEXT_LIGHT_1};
        `;
    }};

    font-weight: 700;
    margin: ${({ margin }: Props) => margin || 0};
    margin-top: ${({ marginTop }: Props) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: Props) => marginBottom || 0};
    margin-left: ${({ marginLeft }: Props) => marginLeft || 0};
    margin-right: ${({ marginRight }: Props) => marginRight || 0};
    text-decoration: none;
    cursor: pointer;
    text-align: center;
`;

export default Button;
