import React, { FormEvent, useCallback, useState } from "react";
import styled from "styled-components";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";
import Paragraph from "./Paragrpah";
import Subtitle from "./Subtitle";
import Title from "./Title";

import UnstyledCheckmark from "../../svgs/Checkmark.svg";
import Button, { ButtonSize, ButtonType } from "./Button";
import Breakpoint from "../../constants/Breakpoint";

const ResultType = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
};

const Container = styled.div`
    width: 800px;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        width: 100%;
    }
`;

const TextInput = styled.input`
    background-color: ${Color.BACKGROUND_LIGHT_1};
    border-color: ${Color.TEXT_DARK_3};
    border-style: solid;
    border-width: 1px;
    padding: ${Spacing.SPACING_1};
    width: 400px;
    border-radius: 5px;

    &:focus {
        outline: none;
    }

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        width: 100%;
    }
`;

const CheckBoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: ${Spacing.SPACING_3};
`;

interface CheckBoxProps {
    checked: boolean;
}

const CheckBox = styled.div<CheckBoxProps>`
    position: relative;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Color.TEXT_DARK_3};
    border-radius: 5px;
    margin-right: ${Spacing.SPACING_1};
    flex-shrink: 0;

    path {
        stroke: ${Color.TEXT_LIGHT_1};
    }

    cursor: pointer;

    ${({ checked }: CheckBoxProps) => {
        if (checked) {
            return `
                background-color: ${Color.BRAND_1};
                border-color: ${Color.BRAND_1};
            `;
        }

        return ``;
    }}
`;

const Checkmark = styled(UnstyledCheckmark)`
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 2px;
    left: 2px;
`;

const Disclaimer = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${Color.TEXT_DARK_4};
    margin: 0px;
    margin-bottom: ${Spacing.SPACING_1};
    margin-top: ${Spacing.SPACING_3};
`;

const ErrorMessage = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${Color.STATUS_CRITICAL};
    margin: 0px;
    margin-bottom: ${Spacing.SPACING_1};
    margin-top: ${Spacing.SPACING_1};
`;

const OutsideLink = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${Color.BRAND_1};
`;

interface SubscribeRequest {
    email: string;
}

const subscribeRequest = async ({ email }: SubscribeRequest) => {
    try {
        const url =
            "https://86f982e4.sibforms.com/serve/MUIEAMpXaVVPdvPzsw6zUHFZJ76NGUwk7A5l0YU3zdvbd3sAfZIEJH_RRv7H1IXVBp_FujRW7K-G1c0oTsmJHlHMHuZMg7-ZtwxSABrZZ2k2CQOr_jkixS-Yz46lAivGeq_dAtJl3u4SrIWd2ypsaLue11lFnA0G8Rv4P_ECHrDd5huMZ5XZngXhFPhobUxjMuhl_O1K-giau9xD?isAjax=1";
        const formData = new FormData();

        formData.append("EMAIL", email);
        formData.append("OPT_IN", "1");

        const res = await fetch(url, {
            method: "POST",
            body: formData,
        });

        if (res.status !== 200) {
            throw "Error subscribing, non 200 response code";
        }

        return ResultType.SUCCESS;
    } catch (e) {
        console.error(e);

        return ResultType.FAILURE;
    }
};

interface Props {
    close: () => void;
}

const SubscribeByMail = ({ close }: Props) => {
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = useCallback(async () => {
        setIsDirty(true);

        console.log("FIRED 1");

        if (!emailValid || !checked) {
            console.log("SJEB 1");
            console.log("EMAIL VALIADN: ", emailValid);
            console.log("CHECKANO: ", checked);
            return;
        }

        console.log("FIRED 2");

        setLoading(true);

        const result = await subscribeRequest({ email });

        if (result === ResultType.SUCCESS) {
            setSubscribed(true);
        }

        setLoading(false);
    }, [emailValid, checked]);

    const checkToggle = useCallback(() => {
        setChecked((checked) => !checked);
    }, []);

    const onEmailChange = useCallback((e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (!target?.value) {
            setEmailValid(false);
        }

        const match = target.value
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

        setEmailValid(!!match);

        setEmail(target.value);
    }, []);

    if (loading) {
        return (
            <Container>
                <Title marginBottom={Spacing.SPACING_3}>Loading...</Title>
                <Paragraph marginBottom={Spacing.SPACING_3}>
                    Your email is being procesed
                </Paragraph>
            </Container>
        );
    }

    if (subscribed) {
        return (
            <Container>
                <Title marginBottom={Spacing.SPACING_3}>
                    Thank you for your subscription!
                </Title>
                <Paragraph marginBottom={Spacing.SPACING_3}>
                    Our product is still in development and not open to the
                    general public. <br />
                    <br />
                    We will contact you with a <strong>
                        special offer
                    </strong>{" "}
                    once the product is ready.
                </Paragraph>
                <Button
                    buttonSize={ButtonSize.REGULAR}
                    buttonType={ButtonType.SECONDARY}
                    onClick={close}
                >
                    Close
                </Button>
            </Container>
        );
    }

    return (
        <Container>
            <Title marginBottom={Spacing.SPACING_3}>Hey!</Title>
            <Paragraph marginBottom={Spacing.SPACING_3}>
                Our product is still in development and not open to the general
                public. <br />
                <br />
                If you are interested you can subscribe to our mailing list and
                we will contact you with a <strong>special offer</strong> once
                the product is ready.
            </Paragraph>
            <Subtitle marginBottom={Spacing.SPACING_2}>
                Enter your address to subscribe:
            </Subtitle>
            <TextInput
                placeholder="example@mail.com"
                onChange={onEmailChange}
                value={email}
            />
            {!emailValid && isDirty && (
                <ErrorMessage>Email is not valid</ErrorMessage>
            )}
            <CheckBoxRow onClick={checkToggle}>
                <CheckBox checked={checked}>
                    {checked && <Checkmark />}
                </CheckBox>
                <Paragraph>
                    I agree to receive updates and accept the terms of use
                </Paragraph>
            </CheckBoxRow>
            {!checked && isDirty && (
                <ErrorMessage>
                    You have to accept the terms of use to proceed
                </ErrorMessage>
            )}
            <Disclaimer>
                You may unsubscribe from the updates at any time using the link
                in our emails. We use Sendinblue as our email platform.By
                Clicking below to submit this form, you acknowledge that the
                information you provided will be transferred to Sendinblue for
                processing in accordance with their{" "}
                <OutsideLink
                    href="https://www.sendinblue.com/legal/termsofuse/"
                    target="_blank"
                >
                    terms of use
                </OutsideLink>
                .
            </Disclaimer>
            <Button
                marginTop={Spacing.SPACING_3}
                buttonSize={ButtonSize.REGULAR}
                buttonType={ButtonType.PRIMARY}
                onClick={submit}
            >
                Subscribe
            </Button>
        </Container>
    );
};

export default SubscribeByMail;
