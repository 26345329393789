import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color, { ColorType } from "../../constants/Color";
import Spacing, { SpacingType } from "../../constants/Spacing";

interface Props {
    backgroundColor?: ColorType;
    margin?: SpacingType;
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
    marginLeft?: SpacingType;
    marginRight?: SpacingType;
}

const Section = styled.section<Props>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ backgroundColor }: Props) => {
        if (!backgroundColor) {
            return Color.BACKGROUND_LIGHT_1;
        }

        return backgroundColor;
    }};

    margin: ${({ margin }: Props) => margin || 0};
    margin-top: ${({ marginTop }: Props) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: Props) => marginBottom || 0};
    margin-left: ${({ marginLeft }: Props) => marginLeft || 0};
    margin-right: ${({ marginRight }: Props) => marginRight || 0};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        padding: 0px ${Spacing.SPACING_2};
    }
`;

export default Section;
