const Color = {
    BACKGROUND_LIGHT_1: "#f8f8f8",
    BACKGROUND_LIGHT_2: "#ededed",
    BACKGROUND_DARK_1: "#555555",
    BRAND_1: "#006666",
    TEXT_DARK_1: "#333333",
    TEXT_DARK_2: "#555555",
    TEXT_DARK_3: "#777777",
    TEXT_DARK_4: "#999999",
    TEXT_LIGHT_1: "#FFFFFF",
    STATUS_CRITICAL: "#A2423D",
    STATUS_WARNING: "#FFCA58",
    STATUS_OK: "#00739D",
    RGBA_BACGROUND_LIGHT_2_A_1: "rgba(237, 237, 237, 1)",
    RGBA_BACGROUND_LIGHT_2_A_0: "rgba(237, 237, 237, 0)",
    RGBA_BACGROUND_DARK_1_A_06: "rgba(85, 85, 85, 0.6)",
    RGBA_BRAND_2: "rgba(0, 102, 102, 0.2);",
} as const;

export type ColorType = typeof Color[keyof typeof Color];

export default Color;
