const Spacing = {
    SPACING_1: "10px",
    SPACING_2: "20px",
    SPACING_3: "40px",
    SPACING_4: "60px",
    SPACING_5: "80px",
    SPACING_6: "100px",
    SPACING_7: "120px",
} as const;

export type SpacingType = typeof Spacing[keyof typeof Spacing];

export default Spacing;
