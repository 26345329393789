import React, { ReactNode } from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";

import Close from "../../svgs/Close.svg";

interface Props {
    children: ReactNode;
    closeAction: () => void;
}

const Background = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: ${Color.RGBA_BACGROUND_DARK_1_A_06};
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    position: relative;
    min-width: 300px;
    min-height: 300px;
    padding: ${Spacing.SPACING_3};
    border-radius: ${Spacing.SPACING_2};
    background-color: ${Color.BACKGROUND_LIGHT_1};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
`;

const CloseContainer = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    box-shadow: none;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin: 0px;
    cursor: pointer;

    path {
        stroke: ${Color.TEXT_DARK_1};
    }
`;

const Modal = ({ children, closeAction }: Props) => {
    return (
        <Background>
            <Container>
                <CloseContainer onClick={closeAction}>
                    <Close />
                </CloseContainer>
                {children}
            </Container>
        </Background>
    );
};

export default Modal;
