import styled from "styled-components";
import Color from "../../constants/Color";
import { SpacingType } from "../../constants/Spacing";

interface Props {
    margin?: SpacingType;
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
    marginLeft?: SpacingType;
    marginRight?: SpacingType;
}

const Subtitle = styled.h3<Props>`
    padding: 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.TEXT_DARK_1};

    margin: ${({ margin }: Props) => margin || 0};
    margin-top: ${({ marginTop }: Props) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: Props) => marginBottom || 0};
    margin-left: ${({ marginLeft }: Props) => marginLeft || 0};
    margin-right: ${({ marginRight }: Props) => marginRight || 0};
`;

export default Subtitle;
