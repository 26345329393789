import styled from "styled-components";

const Main = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export default Main;
